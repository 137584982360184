<div id="header">
    <img src="../assets/dw-logo-white.svg" alt="DecisionWise" />
  </div>

  <div id="app-content">
      <div id="message-list"> 
          <div  class="no-message" *ngIf="messages.Items.length == 0">No messages found</div>

        <ul *ngIf="messages.Items.length > 0">
            <li *ngFor="let message of messages.Items" (click)="loadMessage(message)" [class.unread]="message.isNew == 'true'">
                <p class="message-date">{{message.commonHeaders.date |date:'short'}}</p>
                <p class="message-from">{{message.from}}</p>
                <p class="message-subject">{{message.commonHeaders.subject}}</p>
            </li>
        </ul>
    </div>
    
<div id="reading-pane">
    <div class="no-message" *ngIf="message.data === undefined">No message selected</div>
    <div *ngIf="message.data !== undefined" class="reading-pane-meta">
        <p class="pane-subject">{{message.subject}}</p>
        <p class="pane-from"><span>{{message.from}}</span> <{{message.fromAddress}}></p>
    </div>

    <div id="reading-content" *ngIf="message.data !== undefined">
        <div *ngIf="message.attachments.length > 0" id="attachment-bar">
            <h3>Attachments</h3>
            <ul>
                <li *ngFor="let attachment of message.attachments" (click)="downloadAttachment(attachment)">{{attachment.filename}}</li>
            </ul>
        </div>
        <div [innerHTML]="message.data | safeHtml" id="reading-content-inner" *ngIf="message.data !== undefined"></div>
    </div>
</div>


