<div class="home-wrap">
    <img id="lightbulb" src="../../assets/light-bulb.svg" />
    
    <div class="home-wrap-item">
        <img src="../../assets/dw-logo-blue.svg" alt="DecisionWise" />
        <p class="instructions">Welcome to the DecisionWise temporary email system.</p>
        <p class="instructions">Enter address to access the inbox.</p>
    <p class="input-wrap">
        <input type="text" placeholder="Enter Address" [(ngModel)]="inbox" />@decisionwise.email
    </p>
    <button [routerLink]="['inbox/' + inbox]" [disabled]="inbox.length == 0">Go To Inbox</button>

</div>
</div>