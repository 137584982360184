import { Component, OnInit } from '@angular/core';
import { HttpService } from '../http.service';
import { ActivatedRoute } from '@angular/router';
import parse from 'emailjs-mime-parser';



@Component({
  selector: 'app-inbox',
  templateUrl: './inbox.component.html',
  styleUrls: ['./inbox.component.css']
})
export class InboxComponent implements OnInit {

  messages: any = [];
  raw_message: string;
  message: any = {};
  inbox: string;
  private sub: any;


  constructor(private _http: HttpService, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.messages.Items = [];  
    this.sub = this.route.params.subscribe(params => {
      this.inbox = params['inbox'];
      this._http.getInbox(this.inbox).subscribe(data => {
        this.messages = data;
        this.messages.Items.sort(function(a,b){
          // Turn your strings into dates, and then subtract them
          // to get a value that is either negative, positive, or zero.
          a = new Date(a.timestamp);
          b = new Date(b.timestamp);
          return b - a;
        });
      });
    });
  }

  loadMessage(message) {
    message.isNew = false;
    this._http.getMessage(this.inbox, message.messageId).subscribe(data => {
      this.raw_message = data;

      let parsed = parse(this.raw_message);
      let content = this.getMailContents(parsed);
      let attachments = this.getMailAttachments(parsed);
      this.message.data = content;
      this.message.subject = parsed.headers.subject[0].value;
      this.message.from = parsed.headers.from[0].value[0].name;
      this.message.fromAddress = parsed.headers.from[0].value[0].address;
      this.message.to = parsed.headers.to[0].value[0].name;
      this.message.toAddress =  parsed.headers.to[0].value[0].address;
      this.message.attachments = attachments;
    });
  }

  Decodeuint8arr(uint8array) {
    return new TextDecoder("utf-8").decode(uint8array);
  }

  getMailContents(parsed) {
    let result = '';

    for (const child of parsed.childNodes) {
      if (child.contentType.value === 'multipart/alternative') {
        if (child.childNodes.length > 0) {
          result = this.getMailContents(child);
          if (result !== '') {
            break;
          }
        }
      }
    }

    if (result === '') {
      for (const child of parsed.childNodes) {
        if (child.contentType.value === 'text/html') {
          result = this.Decodeuint8arr(child.content);
          break;
        }
      }
    }

    if (result === '') {
      for (const child of parsed.childNodes) {
        if (child.contentType.value === 'text/plain') {
          result = this.Decodeuint8arr(child.content);
          break;
        }
      }
    }

    if (result === '') {
      if (parsed.childNodes.length === 0) {
        if (parsed.contentType.value === 'text/html') {
          result = this.Decodeuint8arr(parsed.content);
        }
      }
    }

    return result
  }

  getMailAttachments(parsed) {
    let attachments = [];

    for (const child of parsed.childNodes) {
        if (child.headers['content-disposition'] !== undefined) {
          attachments.push({
            //"raw" : child,
            "filename" : child.contentType.params.name,
            "content-type" : child.contentType.value,
            "content" : child.content
          });
        }
    }

    return attachments
  }

  downloadAttachment(attachment){
    this.downloadBlob(attachment.content, attachment.filename, attachment['content-type']);
  }

  downloadURL = function(data, fileName) {
    var a;
    a = document.createElement('a');
    a.href = data;
    a.download = fileName;
    document.body.appendChild(a);
    a.style = 'display: none';
    a.click();
    a.remove();
  };

downloadBlob = function(data, fileName, mimeType) {
  var blob, url, src;
  src = new Blob([new Uint8Array(data).buffer]);
  blob = new Blob([src], {
    type: mimeType
  });
  url = window.URL.createObjectURL(blob);
  this.downloadURL(url, fileName);
  setTimeout(function() {
    return window.URL.revokeObjectURL(url);
  }, 1000);
};



}
