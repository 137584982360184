import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http'
@Injectable({
  providedIn: 'root'
})
export class HttpService {

  constructor(private http: HttpClient) { }

  getInbox(inbox){
    return this.http.get('https://sv264ojoz6.execute-api.us-east-1.amazonaws.com/PROD/' + inbox + '@decisionwise.email');
  }

  getMessage(inbox, messageId){
    return this.http.get('https://sv264ojoz6.execute-api.us-east-1.amazonaws.com/PROD/' + inbox + '@decisionwise.email/' + messageId, { responseType : 'text' });
  }
}
